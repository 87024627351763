<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学生管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/StudentList' }">学生列表</el-breadcrumb-item>
            <el-breadcrumb-item>新建学生</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-form :model="StudentInfo" :rules="StudentInfoRules" ref="StudentInfoRef" label-width="80px">
                <el-alert class="AddStudent_Alert" title="学生基本信息" type="info" show-icon :closable="false"></el-alert>
                <el-form-item label="姓名" prop="Name">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="Gender">
                    <el-radio-group class="AddStudent_Radio" v-model="StudentInfo.Gender">
                        <el-radio label="男" border></el-radio>
                        <el-radio label="女" border></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="年龄" prop="Age">
                    <el-input-number class="AddStudednt_InputNumber" v-model="StudentInfo.Age" :precision="0" :step="1" :min="6" :max="18"></el-input-number>
                </el-form-item>
                <el-form-item label="生日" prop="Birthday">
                    <el-date-picker class="AddStudednt_DatePicker" type="date" 
                    placeholder="选择日期"  
                    format="yyyy 年 MM 月 dd 日" 
                    value-format="yyyy-MM-dd" 
                    v-model="StudentInfo.Birthday"></el-date-picker>
                </el-form-item>
                <el-form-item label="电话" prop="Phone">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Phone"></el-input>
                </el-form-item>
                <el-form-item label="城市">
                    <el-col :span="6">
                        <el-cascader class="AddStudednt_Cascader" :options="Options" v-model="SelectedOptions" @change="SelectedCityHandleChange"></el-cascader>
                    </el-col>
                </el-form-item>
                <el-form-item label="地址" prop="Address">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Address"></el-input>
                </el-form-item>
                <el-alert class="AddStudent_Alert" title="紧急联系人信息" type="info" show-icon :closable="false"></el-alert>
                <el-form-item label="姓名" prop="Guardianbackup">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Guardianbackup"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="GuardianPhonebackup">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.GuardianPhonebackup" autocomplete="off"></el-input> 
                </el-form-item>
                <el-alert class="AddStudent_Alert" title="父亲信息" type="info" show-icon :closable="false"></el-alert>
                <el-form-item label="姓名" prop="Father">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Father"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="FatherPhone">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.FatherPhone"></el-input>
                </el-form-item>
                <el-alert class="AddStudent_Alert" title="母亲信息" type="info" show-icon :closable="false"></el-alert>
                <el-form-item label="姓名" prop="Mother">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.Mother"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="FatherPhone">
                    <el-input class="AddStudent_Input" v-model="StudentInfo.FatherPhone"></el-input>
                </el-form-item>
                <el-alert class="AddStudent_Alert" title="校区信息" type="info" show-icon :closable="false"></el-alert>
                <el-form-item label="学校" prop="SchoolID">
                    <el-select class="AddStudent_Select" v-model="SchoolValue" placeholder="请选择学校" @change="ChangedSchool">
                        <el-option
                        v-for="item in SchoolList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                            <span class="AddStudednt_Span_Lable">{{ item.address }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="等级" prop="SchoolID">
                    <el-select class="AddStudent_Select" v-model="LevelValue" placeholder="请选择等级" @change="ChangedLevel">
                        <el-option
                        v-for="item in LevelList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                            <span class="AddStudednt_Span_Title">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="班级" prop="SchoolID">
                    <el-cascader class="AddStudent_Cascader"
                    :options="LevelClass"
                    @change="LevelClassHandleChange"
                    :props="CascaderProps">
                    </el-cascader>
                </el-form-item>
            </el-form>
            <el-col :span="4">
                <el-button class="AddStudent_Button_Submit" type="primary" @click="SubmitStudentInfo">提交</el-button>
            </el-col>
        </el-card>
    </div>
</template>

<script>
import { provinceAndCityData, CodeToText,TextToCode } from 'element-china-area-data'
export default {
    data() {
        return{
            StudentInfo:{
                Name:'',
                Gender:'男',
                Phone:'',
                Age:6,
                Birthday:'2021-04-01',
                Father:'',
                FatherPhone:'',
                Mother:'',
                MotherPhone:'',
                Guardianbackup:'',
                GuardianPhonebackup: '',
                Province:'山东省',
                City:'潍坊市',
                Address:'',
                LevelID:0,
                ClassID:0,
                SchoolID:0
            },
            StudentInfoRules:{
                Name:[
                    { required: true, message: '请输入学生姓名', trigger: 'blur' }
                ],
                Gender:[
                    { required: true, message: '请选择学生性别', trigger: 'change'}
                ],
                Age:[
                    { required: true, message: '请输入学生学生年龄', trigger: 'blur' }
                ],
                Address:[
                    { required: true, message: '请输入常住地址', trigger: 'blur' }
                ],
                Guardianbackup:[
                    { required: true, message: '请输入紧急联系人姓名', trigger: 'blur' }
                ],
                GuardianPhonebackup:[
                    { required: true, message: '请输入紧急联系人联系方式', trigger: 'blur' }
                ],
                SchoolID: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ],
                LevelID:[
                    { required: true, message: '请选择学生等级', trigger: 'change' }
                ]
            },
            Options: provinceAndCityData,
            SelectedOptions: [],
            SchoolList:[],
            LevelList:[],
            SchoolValue:'',
            LevelValue:'',
            LevelClass:[],
            CascaderProps:{
                value:'id',
                label:'name',
                children:'class',
                disabled: 'disable'
            }
        }
    },
    created(){
        this.SelectedOptions = [TextToCode["山东省"].code.toString(),TextToCode["山东省"]["潍坊市"].code.toString()]
        this.GetSchools()
        this.GetLevelList()
        this.GetLevelClass()
    },
    methods:{
        SelectedCityHandleChange(value) {
            this.StudentInfo.Province = CodeToText[value[0]]
            this.StudentInfo.City = CodeToText[value[1]]
        },
        async SubmitStudentInfo(){
            this.$refs.StudentInfoRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            await this.$http.post('Student/v1/Student',this.StudentInfo).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('新建学生失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.$message.success('新建学生成功')
            this.$router.push('/StudentList')
        },
        async GetSchools(){
            const {data:res} = await this.$http.get('School/v1/Schools')
            if(res.code !== 1){
                return this.$message.error('获取学校列表失败');
            }
            this.SchoolList = res.data;
        },
        async GetLevelList(){
            const {data:res} = await this.$http.get('LevelInfo/v1/LevelInfos')
            if(res.code !== 1){
                return this.$message.error('获取等级列表失败');
            }
            this.LevelList = res.data
        },
        ChangedSchool(key){
            let _this = this
            _this.StudentInfo.SchoolID = key
        },
        ChangedLevel(key){
            let _this = this
            _this.StudentInfo.LevelID = key
        },
        async GetLevelClass(){
            const {data:res} = await this.$http.get('LevelInfo/v1/LevelClass')
            if(res.code !== 1){
                return this.$message.error('获取班级列表失败');
            }
            console.log(res.data.levelInfos)
            this.LevelClass = res.data.levelInfos
        },
        LevelClassHandleChange(value){
            this.StudentInfo.LevelID = value[0]
            this.StudentInfo.ClassID = value[1]
        }
    }
}
</script>

<style scoped>
    .AddStudent_Alert{
        margin-bottom: 20px;
    }
    .AddStudent_Input{
        width: 500px;
        display: flex;
    }
    .AddStudent_Radio{
        display: flex;
    }
    .AddStudednt_DatePicker{
        display: flex;
    }
    .AddStudednt_Cascader{
        width: auto;
        display: flex;
    }
    .AddStudednt_InputNumber{
        width: 200px;
        display: flex;
    }
    .AddStudednt_Span_Title{
        float: left
    }
    .AddStudednt_Span_Lable{
        float: right; 
        color: #8492a6; 
        font-size: 13px
    }
    .AddStudent_Select{
        display:table
    }
    .AddStudent_Button_Submit{
        margin:0 0 20px
    }
    .AddStudent_Cascader{
        display:table
    }
</style>
